@import url('https://fonts.googleapis.com/css2?family=Anton&family=Babylonica&family=Bodoni+Moda:opsz,wght@6..96,400;6..96,500;6..96,700&family=Calligraffitti&family=Cinzel&family=EB+Garamond:ital,wght@0,400;0,600;1,400&family=Fauna+One&family=Henny+Penny&family=Lato:wght@100;300;400&family=League+Spartan:wght@200;300;400;500&family=Libre+Baskerville:wght@400;700&family=Montserrat:wght@100;200;300;400&family=Nunito:wght@200;400&family=Poppins:wght@100;200;300&display=swap');

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
 
   --clr-primary-1: #E5E9EF;
  --clr-primary-2: #33944A;
  --clr-primary-3: #080814;
  --clr-primary-4: #AABADE;
  --clr-primary-5: #544FCC;
  --clr-primary-6:#DFDFF5;
  --clr-primary-1-hover: #c3cbe2;
  --clr-primary-2-hover: #297b3d; 
  --clr-primary-3-hover: #0d0f13; 
  --clr-primary-4-hover: #7d92b4; 
  --clr-primary-5-hover: #3e44a0; 
  --clr-primary-hover:#0056b3;
  --clr-primary-10: #F7F4F8 ;
    --clr-primary-11: #BFE2E6;
  --clr-body: #F1F5FA;
  --clr-white: #fff;
  
  --clr-black: #222;
    --primary-font1: 'Lato', sans-serif;
  --primary-font2: 'Merriweather', serif;
  --primary-font3: 'Montserrat' sans-serif;
  --primary-font4: 'Oswald', sans-serif;
  --primary-font5: 'Playfair Display', serif;
  --primary-font6: 'Raleway', sans-serif;
  --primary-font7: 'Rubik Microbe', cursive;
  --primary-font8: 'Rubik Moonrocks', cursive;
  --primary-font9: 'Anton', sans-serif;
  --primary-font10: 'Babylonica', cursive;
  --primary-font11: 'Bodoni Moda', serif;
  --primary-font12:  'Calligraffitti', cursive;
  --primary-font13: 'EB Garamond', serif;
  --primary-font14:'Henny Penny', cursive;
  --primary-font15:'League Spartan', sans-serif;
  --primary-font16:'Libre Baskerville', serif;
  --primary-font17:'Nunito', sans-serif;
  --primary-font18:'Poppins', sans-serif;
  --primary-font19: 'Cinzel', serif;
  --primary-font20: 'Fauna One', serif;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
  
}



body {
  background: var(--clr-body);
  color: var(--clr-black);
  transition: var(--transition);
  font-family: var(--primary-font3);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section-center {
  width: 90vw;
  margin: 5rem auto;
  max-width: var(--max-width);

}


.title h3{
  text-transform:uppercase;
  font-size: 1.5rem;
  font-weight: 800;
  color: var(--clr-white);
}
.underline{
  width: 10rem;
  height: 1rem;
  background: var(--clr-primary-8);
  margin: 0 auto;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Navbar */
nav {
  background: var(--clr-primary-1);
  box-shadow: var(--light-shadow);
  position: sticky; 
  top: 0;           
  z-index: 10; 
height:auto;     
}
.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.logo {
  width: 150px; 
  height: 100px; 
  object-fit: contain; 
}

@media (min-width: 768px) {
  .logo {
    width: 200px; 
  }
}

@media (min-width: 1200px) {
  .logo {
    width: 200px; 
    height: 150px;
  }
}


.nav-toggle {
  font-size: 1.5rem;
  color: var(--clr-white);
  background-color: var(--clr-primary-5);
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
}
.nav-toggle:hover {
  color: var(--clr-white);
  transform: rotate(90deg);
}

.links a {
  color: var(--clr-primary-3);
  font-size: 1.2rem;
  text-transform: capitalize;
  letter-spacing: var(--spacing);
  display: block;
  padding: 0.5rem 1rem;
  transition: var(--transition);
}
.links a:hover {
  background: var(--clr-primary-3);
  color: var(--clr-primary-5-hover);
  padding-left: 1.5rem;
}

.btn-contact {
  background-color:var(--clr-primary-3); 
  color:var(--clr-white) !important;
  padding: 5px 5px !important;
  margin-left: 2rem !important;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
}

.btn-contact:hover {
  background-color: var(--clr-primary-5-hover) !important;
}

.social-icons {
  display: none;
}
.links-container {
  height: 0;
  overflow: hidden;
  transition: var(--transition);
}
.show-container {
  height: 10rem;
}
.no-wrap {
  white-space: nowrap;
}

@media screen and (min-width: 992px) {
  .nav-center {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  }
  .nav-flex{

    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  
  .nav-header {
    padding: 0;
    
  }
  .nav-toggle {
    display: none;
  }
  .links-container {
    height: auto !important;
  }
  .links {
    display: flex;
  }
  .links a {
    padding: 0;
    margin: 0 0.5rem;
  }
  .links a:hover {
    padding: 0;
    background: transparent;
  }
  .social-icons {
    display: flex;
  }
  .social-icons a {
    margin: 0 0.5rem;
    font-size: 1.5rem;
    color: var(--clr-primary-5);
    transition: var(--transition);
  }
  .social-icons a:hover {
    color: var(--clr-primary-7);
  }
  
}
/* Navigation Styles */
.nav-item {
  list-style: none;
  margin: 0 10px;
}

.nav-link {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #0056b3; /* Adjust to your theme's hover color */
}
.profile-flex{
  margin-top: 1rem;
  display: flex;
}
.profile-flex li{
  background-color: var(--clr-primary-5);
  padding: 0.5rem;
  border-radius: var(--radius);
  
}
.profile-flex li a{
  color: var(--clr-white)!important;
  transition: var(--transition);
  cursor: pointer;
}
.profile-flex li:hover{
  background-color: var(--clr-black);
}
.container-profile{
  display: flex;
  flex-wrap: wrap;
 
}

.username {
  
  font-weight: bold;
  font-size: 1rem;
  color: #555;
}

.avatar-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  
  border: 2px solid #0056b3; /* Optional border color */
}

.logout-button {
  background-color: #ff4d4d; /* Logout button color */
  color: white;
  padding: 5px 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #e60000; /* Darker red for hover effect */
}

/* Dropdown styles for admin links */
.dropdown-item {
  display: flex;
  color: #333;
  padding: 5px 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f4f4f4; /* Light gray background on hover */
  color: #0056b3;
}

.admin-nav{
  display: flex;
  flex-direction: column;
}
/* Herooo */
.hero {
  position: relative;
  display: grid;
  place-items: center; 
  
}

.herocontainer{
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
}
.hero-details{
  max-width: 30rem;
}
.hero-content{
  margin-top: 2rem;
}
.hero-content .dote {
  position: relative;
  display: inline-block;
  margin-right: 2rem; 
}

.hero-content .dote::before {
  content: "";
  position: absolute;
  width: 2rem;  
  height: 2rem;
  background-color: var(--clr-primary-2); 
  border-radius: 50%; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
}

.hero-content .dote::after {
  content: "";
  position: absolute;
  width: 0.5rem;  
  height: 0.5rem; 
  background-color: var(--clr-primary-1); 
  border-radius: 50%; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
}

.hero-content .hero-text {
  font-size: 1.3rem;
  color: var(--clr-primary-2);
}
.hero-content h2{
  font-size: 3rem;
}
.hero-content .hero-text-2{
  color: var(--clr-primary-2);

}
.hero-content p{
  font-size: 1.5rem;
  margin-top: 1rem;
}
.hero-content h3{
  margin-top: 2rem;
}
.hero-image{
  position: relative;
}
.big-img img{
  width: 100%;
}
.first-hero-img {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--clr-body);
  display: inline-block;
  border-radius: 50%;  
  overflow: hidden;  
  animation: rotateImage 10s infinite linear;  
}

.first-hero-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;  
}

@keyframes rotateImage {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.arrow-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;  
  color: var(--clr-primary-2);  
}
 .second-hero-img-content{
  display: none;
 }
 

@media (min-width:1440px) {
  .second-hero-img-content {
    display: block;
  position: absolute;
  top: 40%;
  right:-10rem;
  width: 300px;
  border-radius: 10px; 
  overflow: hidden; 
  background-color: var(--clr-white);
}

.second-hero-img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px; 
}



.title {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.price {
  color: var(--clr-primary-2);
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.location {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.location-icon {
  margin-right: 0.5rem;
  font-size: 1.5rem;
}

.location-name {
  font-size: 1.2rem;
}

}
/* SearchTerm */
.search-term {
  display: flex;
  max-width:var(--max-width);
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.search-container,
.search-container-two{
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
}
.search-input{
 width: 100%;
  padding: 0.75rem;
  border-radius: var(--radius);
  border:transparent;
  background-color: var(--clr-body);
}
.search-input::placeholder{
  font-size: 1rem;
}
.search-button {
  text-transform: capitalize;
  background-color:var(--clr-primary-2); 
  color:var(--clr-white); 
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0.8rem 2rem; 
  border: none;
  border-radius: 30px; 
  cursor: pointer;
  transition: all 0.3s ease;
}

.search-button:hover {
  background-color:var(--clr-primary-2-hover); 
  transform: translateY(-3px); 
}

.search-button a {
  text-decoration: none; 
  color: inherit; 
  display: block;
}

 .search-button:focus {
  outline: none; 
}
@media (min-width:992px) {
  .search-container{
   display: grid;
   grid-template-columns: repeat(4,1fr);
   gap: 2rem;
  }
  .search-container-two{
    display: grid;
   grid-template-columns: repeat(7,1fr);
   gap: 2rem;
  }
}
/* Companies */
.companies{
  position: relative;
  background-color: var(--clr-white);
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.companies-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.companies-title h2 {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin: 0 20px; 
}

.companies-title h2 span {
  color:var(--clr-primary-5); 
}

.line {
  display: none;
}
.container-companies{
  text-align: center;
}
.companies-details img{
  background-color: var(--clr-body);
  border-radius: var(--radius);
  padding: 0.5rem;
  margin-top: 1rem;
}
@media (min-width:768px) {
  .container-companies{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 5rem;
  }
}
@media (min-width:992px) {
  .container-companies{
   
    grid-template-columns: repeat(3,1fr);
    
  }
  .line {
    display: block;
  flex: 1;
  height: 2px;
  background-color: #ccc; 
}
}

/* HomeAbout */
/* Add this class to control the image movement */
.home-about-img.move {
  transform: translateY(-20px); /* Adjust as needed */
  transition: transform 0.3s ease-in-out;
}

.home-about-container{
  align-items: center;
}
.hero-home-about h3{
  font-size: 2.5rem;
  margin-top: 1rem;
}
.home-about-content p{
  max-width: 30rem;
  font-size: 1.2rem;
  color: var(--clr-primary-2) !important;
}
.questions {
  margin: 2rem;
}

.question-item {
  margin-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
}

.question-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
}

.question-description {
  margin-top: 1rem;
  font-size: 1.1rem;
  line-height: 1.5;
  color: #555;
}

.question-title h2 {
  margin: 0;
}

.question-title svg {
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}


.question-title svg.fa-angle-up {
  transform: rotate(180deg);
}

.more-about-us {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.btn {
  text-transform: capitalize;
  background-color:var(--clr-primary-2); 
  color:var(--clr-white); 
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0.8rem 2rem; 
  border: none;
  border-radius: 30px; 
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn:hover {
  background-color:var(--clr-primary-2-hover); 
  transform: translateY(-3px); 
}

.btn a {
  text-decoration: none; 
  color: inherit; 
  display: block;
}

 .btn:focus {
  outline: none; 
}

.home-about-details {
  flex: 1;
}

.home-about-content {
  margin-top: 1.5rem;
}

.home-about-content p {
  line-height: 1.6;
  color: #333; 
}
.home-about-img {
  position: relative;
  max-width: 400px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.home-about-img img {
  width: 100%;
  height: auto;
  display: block;
}


.details-home-about-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6); 
  color: #fff;
  padding: 1rem;
}

.home-about-content-img article {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.home-about-content-img article h3 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
}

.home-about-content-img article p {
  margin: 0;
  font-size: 0.9rem;
  color:var(--clr-primary-11); 
}

.home-about-content-img article span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
}

.home-about-content-img article span svg {
  font-size: 1.2rem;
  color:var(--clr-primary-4); 
}
@media (min-width: 768px) {
  .homeAbout {
    padding: 3rem;
  }
}

@media (min-width: 992px) {
  .home-about-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
  }

  .container-questions {
    margin-top: 2rem;
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    flex: 1; 
  }
}
/* Property */
.property{
  background-color: var(--clr-white);
  padding-top: 5rem;
  padding-bottom: 5rem;
}


.property-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.property-card {
  position: relative;
  background-color:var(--clr-white);
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.property-card a{
  color: var(--clr-black);
}
.property-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.property-details {
  padding: 15px;
}

.property-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.property-description {
  font-size: 1rem;
  color: #555;
  margin: 10px 0;
}

.property-price {
  font-size: 1.25rem;
  color:var(--clr-primary-2);
  font-weight: bold;
}

.property-location p {
  font-size: 1rem;
}

.property-map-link {
  color:var(--clr-primary-5);
  text-decoration: none;
}
.property-stats{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.property-stats p {
  font-size: 1rem;
  margin: 5px 0;
}
.property-status{
  position: absolute;
  top: 0;
  
}
.property-status p {
  font-size: 1rem;
  font-weight: bold;
 color: var(--clr-black);
 background-color: var(--clr-white);
  border-radius: var(--radius);
  padding: 0.5rem;
}
/* SinglePropertie */
.singlePropertie {
  background-color: var(--clr-white);
  padding: 2rem;
  font-family: 'Arial', sans-serif;
}

.singlePropertie-details {
  margin-top: 2rem;
  padding: 2rem;
  background-color: var(--clr-light-gray);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.singlepropertie-big-img img {
  width: 100%;
  object-fit: cover;
  height: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.fisrt-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
  gap: 1.5rem;
}

.fisrt-details article {
  flex: 1 1 calc(33.333% - 1.5rem);
  background-color: var(--clr-white);
  padding: 1rem;
  border: 1px solid var(--clr-gray);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fisrt-details p {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.property-images {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.property-images img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 2px solid var(--clr-gray);
  border-radius: 5px;
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.property-images img.thumbnail-active {
  border-color: var(--clr-primary);
  transform: scale(1.1);
}

.property-images img:hover {
  transform: scale(1.1);
}

.map-container {
  margin-top: 2rem;
  height: 400px;
  position: relative;
  border: 1px solid var(--clr-gray);
  border-radius: 10px;
  overflow: hidden;
}

hr {
  margin: 2rem 0;
  border: none;
  border-top: 1px solid var(--clr-gray);
}
@media (min-width:992px) {
  .singlePropertie-details {
    display: grid;
    grid-template-columns:repeat(2,1fr) ;
    gap: 5rem;
  }
}
.message-list-form {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.message-list-input-field,
.message-list-textarea-field {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.message-list-textarea-field {
  min-height: 120px;
}

.message-list-submit-button {
  width: 100%;
  padding: 14px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.message-list-submit-button:disabled {
  background-color: #b0e57c;
  cursor: not-allowed;
}

.message-list-submit-button:hover:not(:disabled) {
  background-color: #45a049;
}

.success-message {
  padding: 15px;
  background-color: #dff0d8;
  color: #3c763d;
  border: 1px solid #d6e9c6;
  border-radius: 4px;
  text-align: center;
  margin-top: 20px;
}

.error-message {
  padding: 15px;
  background-color: #f2dede;
  color: #a94442;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  text-align: center;
  margin-top: 20px;
}
/* View All Properties Section */
.view-all {
  margin-top: 2rem;
  text-align: center;
}

.btn-view {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: var(--clr-primary-2);
  color: var(--clr-white);
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-view:hover {
  background-color: var(--clr-primary-2-hover);
  transform: translateY(-3px);
}

.btn-view a {
  color: var(--clr-white);
  text-decoration: none;
}

.btn-view a:hover {
  text-decoration: underline;
}


/* HeroSuable */
.heroResuable {
  position: relative;
  background: var(--clr-body);
  color:var(--clr-black);
  text-align: center;
  padding: 80px 20px;
  margin-bottom: 40px;
  border-radius: 8px;
}

.heroResuable-content {
  max-width: 900px;
  margin: 2rem auto;
}

.heroResuable-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 15px;
}
.subtitle{
  font-size: 1.5rem;
  padding-top: 1rem;
}
.heroResuable-description {
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 25px;
  max-width: 700px;
  margin: 0 auto;
}
.img-resuable-1{
  position: absolute;
  top: 5rem;
  left: 15rem;
  display: none;
}
@media (max-width: 768px) {
  .heroResuable-title {
    font-size: 2rem;
  }

  .heroResuable-description {
    font-size: 1rem;
  }
  
}
@media (min-width:992px) {
  .img-resuable-1{
    display: block;
    left: 45rem;
  }
}
/* Testimonial */
.testimonial {
  background-color: var(--clr-primary-3);
  padding: 5rem 1rem;
  color: var(--clr-white);
}
.testimonial-content-dote h2{
  padding-top: 1rem;
}
.testimonial-content-dote .dote {
  position: relative;
  display: inline-block;
  margin-right: 2rem;
}

.testimonial-content-dote .dote::before {
  content: "";
  position: absolute;
  width: 2rem;
  height: 2rem;
  background-color: var(--clr-white);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.testimonial-content-dote .dote::after {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--clr-primary-2);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.testimonial-dote-text {
  font-size: 2rem;
}

.testimonial-slider {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 2rem;
}

.testimonial-slider-button {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.prev-btn,
.next-btn {
  background-color: var(--clr-primary);
  color: var(--clr-white);
  font-size: 1.5rem;
  padding: 0.5rem;
  border: 1px solid var(--clr-body);
  border-radius: 100%;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  
}

.prev-btn:hover,
.next-btn:hover {
  background-color: var(--clr-primary-2);
  color: var(--clr-primary-3);
}

.testimonial-item {
  text-align: center;
  padding: 1rem;
  background-color: var(--clr-white);
  color: var(--clr-dark-grey);
  border-radius: 8px;
}

.testimonial-item img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.testimonial-item h3 {
  font-size: 1.5rem;
  color: var(--clr-primary-4);
  margin-bottom: 0.5rem;
}

.testimonial-description {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: var(--clr-black);
}

.testimonial-profession {
  font-size: 0.9rem;
  color: var(--clr-primary-2);
}


@media (min-width: 768px) {
  .testimonial-slider {
    grid-template-columns: repeat(2, 1fr);
  }
  .testimonial-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
/* LastProperties */
.property-last{
  background-color: var(--clr-body);
  padding-top: 5rem;
  padding-bottom: 5rem;
}
/* Agents */
.agents{
  background-color: var(--clr-white);
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.agents-list{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.agent-card {
  position: relative;
  background-color:var(--clr-white);
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.agent-card a{
  color: var(--clr-black);
}
.agent-image{
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.agent-details {
  padding: 15px;
}

.agent-name {
  font-size: 1.5rem;
  font-weight: bold;
}

.agent-description {
  font-size: 1rem;
  color: #555;
  margin: 10px 0;
}
.agent-social-media-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.social-icons a {
  color: #3b5998; /* Facebook color */
  text-decoration: none;
}

.social-icons a:hover {
  color: #8b9dc3; /* Hover effect */
}

.agent-website a {
  text-decoration: none;
  color: #0073e6; /* Blue link color */
}

.agent-website a:hover {
  color: #005bb5; /* Hover effect */
}
/* SingleAgent */
.agent-details-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.agent-info {
  flex: 1;
  max-width: 600px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.agent-info:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.agent-info img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.agent-info h2 {
  font-size: 2rem;
  margin-top: 20px;
  font-weight: 600;
  color: #333;
}

.agent-info p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin: 10px 0;
}

.agent-info p strong {
  font-weight: bold;
  color: #007bff;
}

.social-media {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.social-media a {
  color: #3b5998;
  transition: color 0.3s ease;
}

.social-media a:hover {
  color: #007bff;
}

.agent-websiteSingle {
  margin-top: 30px;
}

.agent-websiteSingle a {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.agent-websiteSingle a:hover {
  background-color: #0056b3;
  color: var(--clr-black);
}

/* Responsive Styling */
@media (max-width: 768px) {
  .agent-details-container {
    flex-direction: column;
  }

  .agent-info {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .social-media {
    justify-content: center;
    margin-top: 20px;
  }

  .agent-website {
    text-align: center;
  }
}
/* Blogs */
.blogs .second-container-blog {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.blog-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-content {
  padding: 16px;
}

.blog-content h3 {
  font-size: 1.25rem;
  margin-bottom: 8px;
}

.blog-content p {
  font-size: 1rem;
  margin-bottom: 12px;
  color: #555;
}

.blog-meta {
  font-size: 0.9rem;
  color: #777;
  display: flex;
  justify-content: space-between;
}
/* SingleBlog */
.single-blog-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.single-blog-container h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.author-info {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 1.5rem;
}

.author-info h3 {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

.post-content {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #444;
  margin-bottom: 2rem;
}

.post-image {
  margin-top: 1.5rem;
  text-align: center;
}

.post-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .single-blog-container {
    padding: 1rem;
  }

  .single-blog-container h1 {
    font-size: 2rem;
  }

  .post-content {
    font-size: 1rem;
  }
}

/* BannerReusable styles */
.banner-reusable {
  width: 100%;
  background-color: #f4f4f4; /* Light background for the banner */
  padding: 40px 0;
}

.section-center-reusable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.banner-image-container {
  flex: 1;
  max-width: 50%;
}

.banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px; /* Optional rounded corners for image */
}

.banner-content {
  flex: 1;
  padding: 20px;
  max-width: 45%;
}

.banner-content h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.banner-content p {
  font-size: 1.125rem;
  margin-bottom: 20px;
  color: #555;
}

.navigate-btn {
  margin-top: 20px;
}



/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .section-center-reusable {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .banner-image-container {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .banner-content {
    max-width: 100%;
  }

  .banner-content h2 {
    font-size: 2rem;
  }

  .banner-content p {
    font-size: 1rem;
  }
}

.first-banner-img-resuable{
  position: absolute;
 bottom: 5rem;
 left: 50%;
}
.second-banner-img-resuable{
  position: absolute;
  top:1rem;
  left: 50%;
}
@media (min-width:992px) {
  .section-center-resuable{
    display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: center;
   gap: 5rem;
  }
  .banner-image-container {
 
  max-width: 450px;
}
}
/* Footer */
.footer {
  position: relative;
  background-color: var(--clr-primary-3);
  padding: 2rem 1rem;
  color:var(--clr-white);
  padding-top: 5rem;
  
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
}

.footer-about,
.footer-newsletter,
.footer-links,
.footer-utility,
.footer-contact {
  flex: 1 1 200px;
}

.footer-logo {
  max-width: 150px;
  margin-bottom: 1rem;
}

.footer h4 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.footer p {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  line-height: 1.6;
}

.footer-links ul,
.footer-utility ul {
  list-style: none;
  padding: 0;
}

.footer-links ul li,
.footer-utility ul li {
  margin-bottom: 0.5rem;
}

.footer-links ul li a,
.footer-utility ul li a {
  color:var(--clr-white);
  text-decoration: none;
}

.footer-links ul li a:hover,
.footer-utility ul li a:hover {
  text-decoration: underline;
}

.footer-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.footer .btn {
  background-color: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.footer .btn:hover {
  background-color: #0056b3;
}

.footer-contact a {
  color: #007bff;
  text-decoration: none;
}

.footer-contact a:hover {
  text-decoration: underline;
}

.footer-copyright {
  text-align: center;
}

.footer-copyright p {
  margin: 0;               
  line-height: 1.6;        
}

.footer-copyright a {
  color: #0077cc;           
  text-decoration: none;   
  font-weight: bold;       
}

.footer-copyright a:hover {
  text-decoration: underline; 
  color: #0056a3;           
}

.first-footer-img{
  position: absolute;
  top: 5rem;
}
.second-footer-img{
  position: absolute;
  bottom: 0;
  left: -5rem;
}
.third-footer-img{
  position: absolute;
  top: 0;
  right:0;
}
.four-footer-img{
  position: absolute;
 bottom: 0;
  right:2rem;
}
/* ChooseUs */
.choose-us {
  background-color: var(--clr-primary-3);
  color: var(--clr-white);
  padding-top: 5rem;
}

.card-choose-container article {
  position: relative;
  background-color: var(--clr-white);
  color: var(--clr-primary-3);
  border-radius: var(--radius);
  cursor: pointer;
  transition: var(--transition);
}

.card-choose-container article span {
  font-size: 3rem;
  padding: 2rem;
}

.card-choose-container article h3,
.card-choose-container article p {
  padding: 1rem;
  font-size: 1.5rem;
  color: var(--clr-primary-3);
}

.card-icons {
  font-size: 5rem;
  color: #808080;
  position: absolute;
  top: 0;
  right: 0;
}

.card-choose-container article:hover {
  background-color: var(--clr-primary-5);
  color: var(--clr-white);
}


.card-choose-container article:hover p,
.card-choose-container article:hover h3 {
  color: var(--clr-white);
}

@media (min-width: 768px) {
  .card-choose-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}

@media (min-width: 992px) {
  .card-choose-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
/* ProgressBar container */
.progressBar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.progress-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 900px; 
  padding: 1rem;
}

.count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
  position: relative;
  padding: 1rem;
  text-align: center;
}


.progress-bar .count:not(:last-child) {
  border-right: 2px solid #ccc; 
  padding-right: 2rem; 
  margin-right: 2rem; 
}


.count span {
  font-size: 2.5rem;
  font-weight: bold;
}


.count:hover {
  background-color: var(--clr-primary-5);
  color: var(--clr-white);
  border-radius: var(--radius);
  transition: background-color 0.3s ease, color 0.3s ease;
}
@media (min-width:992px) {
  .progress-bar {
 
  flex-wrap: nowrap;

 
}

}
/* Partners */
.partenaire {
  padding: 2rem 0;
}

.partenaire-title {
  text-align: center;
  margin-bottom: 2rem;
}

.partenaire-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.partenaire-item {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.partenaire-logo {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.contact-info a,
.website a {
  color: var(--clr-primary-5);
  text-decoration: none;
}

.social-media {
  margin-top: 1rem;
}

.social-icon {
  margin: 0 0.5rem;
  font-size: 1.5rem;
  color: #555;
  transition: color 0.3s;
}

.social-icon:hover {
  color:var(--clr-primary-5-hover);
}

/* AboutPage */
.services{
  background-color: var(--clr-white);
  padding-top: 5rem;

}
.services-content{
  padding-top: 1rem;

}
.services-details{
  background-color: var(--clr-body);
 padding: 2rem;

}
.services-content h3 span{
margin-left: 1rem;
color: var(--clr-primary-2);

}
.services-content p{
  max-width: 30rem;
}
.container-services{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}
@media (min-width:992px) {
  .container-services{
    flex-wrap: nowrap;
  }
}
.video-placeholder {
  position: relative;
  cursor: pointer;
}

.placeholder-img {
  width: 100%;
  height: auto;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 20px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: pulse 2s infinite;
  overflow: hidden;
}

.play-button .flame {
  position: absolute;
  width: 40px;
  height: 40px;
  background: radial-gradient(circle, orange, red);
  border-radius: 50%;
  opacity: 0.7;
  animation: flicker 1s infinite alternate;
}

@keyframes flicker {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1.2);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Close Button Styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  z-index: 10;
}

.close-button:hover {
  background-color: rgba(255, 0, 0, 0.8);
}

/* Placeholder Image Styling */
.video-placeholder {
  position: relative;
  cursor: pointer;
}

.placeholder-img {
  width: 100%;
  height: auto;
}

.video-container {
  position: relative;
}

.video-element {
  width: 100%;
  height: auto;
}
.mission{
  position: relative;
}
.missions-container{
  display: flex;
  flex-wrap: wrap;
   gap: 5rem;
}
.mission-card{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5rem;
  max-width: 35rem;
 
}
.mission-card div{
margin-top: 1rem;
 background-color:var(--clr-primary-11);
 padding: 1rem;
 border-radius: var(--radius);
 box-shadow: var(--dark-shadow);
}
.mission-card h3{
  margin-top: 1rem;
  font-size: 1.5rem;
}
.mission-card p{
  font-size: 1.2rem;
}

.img-mission img{
  width: 100%;
  object-fit: cover;
  box-shadow: var(--dark-shadow);
  border-radius: var(--radius);
}
.img-mission-absolute{
  position: absolute;
  top:-5rem;
  left: 50%;
}
@media (min-width:992px) {
  .missions-container,
  .mission-card{
    flex-wrap: nowrap;
  }
  .img-mission img{
  width: 600px;
 
}
.mission-line{
  background-color:  #505050;
  width: 35rem;
  height: 0.10rem;
 
 
}
}
.approach-img{
  position: relative;
}
.approach-img .approach-img-first{
  width: 100%;
  object-fit: cover;
  box-shadow: var(--dark-shadow);
  border-radius: var(--radius);
  
}
.approach-img-absolute{
  position: absolute;
  top: 25rem;
  right: -3rem;
  z-index: -1;
  display: none;
}
.approach-details-img{
  background-color: var(--clr-white);
  color: var(--clr-black);
  position: absolute;
  top: 60%;
  left: 10rem;
  border-radius: var(--radius);
  padding: 1rem;
 opacity: 0.7;

}
.approach-content{
  display: flex;
   justify-content: space-between;
   gap: 5rem;
   align-items: center;
   flex-wrap: wrap;
}
.approach-content div {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}
.approach-listings{
  margin-top: 2rem;
}
.approch-listings-details div{
  display: flex;
  justify-content: center;
  align-items: center;
}
.approch-listings-details span{
margin: 0.5rem;
}
.approch-listings-details p{
  font-size: 1.2rem;
}
@media (min-width:992px) {
  .approach-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
  }
  .approach-img{
    flex: 2;
  }
  .approach-details{
    flex: 1;
  }
  .approach-img-absolute{
  display: block;
}
}
.contact-emails {
  background-color: var(--clr-white);
 padding: 1rem;

}

.contact-email-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

.contact-email-container article {
  margin-top: 2rem;
  flex: 1;
  text-align: center; 
  position: relative; 
}

.contact-email-container h3 {
  margin-top: 1rem;
}

.contact-email-container img {
  background-color: var(--clr-primary-6);
  padding: 0.75rem;
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
}

.contact-email-container article:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: var(--clr-primary-6); 
}
.contact-form{
  background-color: var(--clr-body);
  padding: 2rem;
  border-radius: var(--radius);
}
.contact-form {
  background-color: var(--clr-body);
  padding: 2rem;
}

.form-contact {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

.form-contact-container article {
  flex: 1 1 45%; 
}

.form-contact-container article:nth-child(3) {
  flex: 1 1 100%; 
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  margin-top: 0.5rem;
  border: 1px solid var(--clr-gray);
  border-radius: var(--radius);
}

.btn {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: var(--clr-primary-5);
  color: var(--clr-white);
  border: none;
  border-radius: var(--radius);
  cursor: pointer;
}

@media (min-width: 768px) {
  .form-contact-container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .form-contact-container {
    gap: 2rem;
  }
}
.google-login span{
  font-size: 2rem;
}
/* AdminCss */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 2rem;
  color: #333;
}

.btn {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-edit {
  background-color: #ffc107;
  color: white;
}

.btn-edit:hover {
  background-color: #e0a800;
}

.btn-delete {
  background-color: #dc3545;
  color: white;
}

.btn-delete:hover {
  background-color: #c82333;
}

.property-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.property-card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
}

.property-card:hover {
  transform: translateY(-5px);
}

.property-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.property-info {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.property-info h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.property-info p {
  color: #555;
  margin-bottom: 8px;
}

.user-profile {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.profile-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 15px;
}

.profile-info h3 {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 5px;
}

.profile-info p {
  color: #555;
  margin-bottom: 5px;
}

.property-actions {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f9f9f9;
}

.property-actions .btn {
  width: 48%;
}

.property-actions .btn-edit,
.property-actions .btn-delete {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.property-actions .btn-edit svg,
.property-actions .btn-delete svg {
  margin-right: 5px;
}

.property-actions .btn-edit:hover,
.property-actions .btn-delete:hover {
  transform: scale(1.05);
}

.property-actions .btn-edit:active,
.property-actions .btn-delete:active {
  transform: scale(1);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #007bff;
}

.error {
  color: #dc3545;
  text-align: center;
  font-size: 1.2rem;
}

.property-list p {
  text-align: center;
  font-size: 1.2rem;
  color: #555;
}
/* General styles for the container */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Form styles */
form {
  display: flex;
  flex-direction: column;
}

form div {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

input, select, textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

textarea {
  resize: vertical;
  height: 150px;
}

input[type='checkbox'] {
  width: auto;
  margin-right: 5px;
}

button[type='submit'] {
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type='submit']:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button[type='submit']:not(:disabled):hover {
  background-color: #0056b3;
}

/* Image preview styles */
.image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-item {
  position: relative;
  width: 150px;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}



/* Map container */
.map-container {
  margin-top: 20px;
  width: 100%;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

/* Profile image preview styles */
.image-preview img {
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
  border-radius: 8px;
}

/* Styling for the "Full Address" field */
#fullAddress {
  background-color: #e9ecef;
  cursor: not-allowed;
}

/* Responsive styling */
@media (max-width: 768px) {
  .container {
    padding: 15px;
  }

  h1 {
    font-size: 24px;
  }

  form div {
    margin-bottom: 10px;
  }

  button[type='submit'] {
    font-size: 14px;
    padding: 10px 16px;
  }

  .image-preview {
    flex-direction: column;
  }
}
.dashboard-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.chart-container {
  margin-bottom: 30px;
}

.summary-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.summary-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 45%;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.summary-card h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.summary-card p {
  font-size: 2rem;
  font-weight: bold;
  color: #007bff;
}

@media (max-width: 768px) {
  .summary-container {
    flex-direction: column;
    align-items: center;
  }

  .summary-card {
    width: 80%;
    margin-bottom: 20px;
  }
}
.user-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

/* User list container */
.user-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/* Individual user card */
.user-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 280px;
  text-align: center;
}

.user-info div {
  margin-bottom: 10px;
  font-size: 14px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Admin status icons */
.fa-check {
  color: green;
  font-size: 18px;
}

.fa-times {
  color: red;
  font-size: 18px;
}

.form-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

/* Heading */
.form-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

/* Form */
.forgot-password-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.reset-password-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: Arial, sans-serif;
}

.reset-password-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.form-group input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
}

.update-button {
  width: 100%;
  padding: 10px;
  background: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.update-button:hover {
  background: #0056b3;
}

.update-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.reset-password-container .loader {
  margin-top: 10px;
  text-align: center;
}
/* Profile Page Styling */
.profile-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.profile-label {
  font-size: 16px;
  color: #333;
}

.profile-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}

.profile-input:focus {
  border-color: #007bff;
}

.profile-file-input {
  margin-top: 10px;
}

.profile-image-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.avatar-preview {
  display: flex;
  align-items: center;
  gap: 10px;
}

.avatar-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.delete-avatar-btn {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.delete-avatar-btn:hover {
  background-color: darkred;
}

.submit-btn {
  background-color: #007bff;
  color: white;
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.submit-btn:hover:enabled {
  background-color: #0056b3;
}

.wishlist-screen {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.wishlist-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.wishlist-item {
  background: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px; /* Fixed height for all cards */
}

.property-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px; /* Space between image and property info */
}

.property-info {
  flex-grow: 1; /* This allows the text area to take up remaining space */
  padding: 10px 0;
  overflow: hidden; /* Hide overflow if the text is too long */
  text-overflow: ellipsis; /* Add "..." when text overflows */
  white-space: nowrap; /* Prevent wrapping of text */
}

button {
  padding: 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px; /* Add some spacing between text and button */
}

button:disabled {
  background-color: #ccc;
}
